import { useEffect } from "react";
/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet';
import SEO from "../components/seo";
import Layout from "../components/layout";
import { PortableText } from "@portabletext/react";
import PortableTextComponent from "../components/serializers/portableTextComponents.js";
import "../scss/page/topics.scss";
import "../scss/page/home.scss";
import "../scss/page/benefits.scss";
import "../scss/page/security.scss";
import "../scss/page/about.scss";
import "../scss/page/partnerships.scss";
import "../scss/page/book.scss";
import "../scss/page/apfm.scss";
import "../scss/page/componentLibrary.scss";
import "../scss/page/benefits2024.scss";
const PageDefinition = ({ data }) => {
  const node = data.page;
  const metaTitle = node.metaTitle ? node.metaTitle : node.title;
  const metaDescription = node.metaDescription ? node.metaDescription : "";

  useEffect(() => {
    if (node.slug.current === "aplaceformom") {
      const quizWidgetDiv = document.createElement("div");

      quizWidgetDiv.setAttribute("class", "ep-quiz-widget");

      const targetDiv = document.getElementById("apfm-hero");

      targetDiv && targetDiv.after(quizWidgetDiv);

      const script = document.createElement("script");
      const topics = ["apfm"];

      script.setAttribute("data-ot-ignore", "");
      script.setAttribute("data-topics", JSON.stringify(topics));
      script.setAttribute("id", "quiz-widget");
      script.setAttribute("type", "module")
      
      script.src = `${process.env.GATSBY_QUIZ_WIDGET_HOST}/quizWidgetApp.js?` +
        new Date().getTime();

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [node.slug]);

  return (
    <div id={node.slug.current}>
      <SEO title={metaTitle} lang="en" description={metaDescription}></SEO>
      <Layout node={node}>
        <Helmet>
          {
            node.slug.current === "aplaceformom" && 
            <link
              href={`${process.env.GATSBY_QUIZ_WIDGET_HOST}/quizWidgetApp.css`}
              rel="stylesheet" 
            />
          }
        </Helmet>
        {node._rawEntities && (
          <div className="entities-container">
            <PortableText
              value={node._rawEntities}
              components={PortableTextComponent}
            />
          </div>
        )}
      </Layout>
    </div>
  );
};
export default PageDefinition;

export const pageDefinitionQuery = graphql`
  query PageDefinitionBySlug($slug: String!) {
    page: sanityPageDefinition(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      metaTitle
      metaDescription
      title
      _rawEntities(resolveReferences: { maxDepth: 15 })
    }
  }
`;
